$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

var loadContent = function (page) {
    var $content = $('[data-content]');
    var viewQuery = $(':input[name^="Query"]').filter(function () { return this.value.length !== 0; }).serializeObject();
    var content = $('[data-content-form]').serializeObject();

    var result = $.extend({}, viewQuery, content, { page: page || null });
    var url = $content.attr('data-content');

    if (!url)
        return;

    $content.empty().append('<div class="text-center" style="margin-top: 100px;"><i class="fa fa-spinner fa-spin fa-3x"></i></div>');
    $.ajax({
        url: url,
        type: "POST",
        dataType: "html",
        data: result,
        cache: false
    }).done(function (response) {
        $content.html(response).trigger('refresh');
    });

};

$('body').on('submit', '[data-content-trigger="submit"]', function (e) {
    e.preventDefault();
    loadContent(); // Load content on submit
}).on('change', '[data-content-trigger="change"]', function (e) {
    e.preventDefault();
    loadContent(); // Load content on change
}).on('click', '[data-content-page]', function (e) {
    e.preventDefault(); // Load content for specific page
    loadContent($(this).attr('data-content-page'));
}).on('click', '[data-sorting-for]', function (e) {
    e.preventDefault();

    $(this).siblings(':input[name^="Query"]').prop('disabled', false);
    $('[data-sorting-for="' + $(this).attr('data-sorting-for') + '"]').not(this).siblings(':input[name^="Query"]').prop('disabled', true);

    $(this).closest('form').trigger('submit');
});

if ($('[data-content]').length > 0) {
    loadContent();
}