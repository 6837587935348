(function ($) {
    var typeEnum = {
        ADD: 0,
        SUBTRACT: 1
    }

    var update = function ($int, type) {
        var val = parseInt($int.find("input:hidden").val());

        if (type === typeEnum.ADD) {
            val++;
        } else if (type === typeEnum.SUBTRACT && val > 1) {
            val--;
        }
        
        $int.find("input:hidden").val(val).change();
        $int.find(".product-count").html(val);
    };

    $.fn.intUpDown = function () {
        return this.each(function () {
            var $int = $(this);

            $int.find(".increment").on("click", function () {
                update($int, typeEnum.ADD);
            });

            $int.find(".decrement").on("click", function () {
                update($int, typeEnum.SUBTRACT);
            });
        });
    };
}(jQuery));