(function ($) {
    $.fn.labelDatepicker = function () {
        return this.each(function () {
            var $element = $(this);
            var $label = $("[data-for=\"" + $element.attr("id") + "\"]");
            var isRequired = $element.attr('data-datepicker-required') == 'true';

            $element.datepicker({
                format: "yyyy-mm-dd",
                language: "nl-NL",
                autoclose: true,
                maxViewMode: 0,
                minViewMode: 0,
                zIndexOffset: 40,
                clearBtn: !isRequired
            }).on("changeDate", function (e) {
                if (e.dates.length == 0) {
                    $("[data-for=\"" + $(this).attr("id") + "\"]").text("");
                } else {
                    var text = e.format(0, "DD dd MM");
                    $("[data-for=\"" + $(this).attr("id") + "\"]").text(text[0].toUpperCase() + text.substr(1));
                }
            }).on("show", function () {
                $("[data-for=\"" + $(this).attr("id") + "\"]").addClass("focus");
            }).on("hide", function () {
                $("[data-for=\"" + $(this).attr("id") + "\"]").removeClass("focus");
            });

            $label.on("click", function () {
                $element.focus();
            });

            // Set initial date
            if ($element.val()) {
                var dpg = $.fn.datepicker.DPGlobal;
                var date = new Date($element.val());

                $element.val(dpg.formatDate(date, "yyyy-mm-dd", "nl-BE"));

                var text = dpg.formatDate(date, "DD dd MM", "nl-BE");
                $label.text(text[0].toUpperCase() + text.substr(1));
            }
        });
    };
}(jQuery));