(function ($) {
    var drawMonth = function (year, month, markedDays) {
        year = year * 1;
        month = month * 1;

        var activeDays = {};
        $.map(markedDays, function (val, i) {
            activeDays[val] = true;
        });

        var today = new Date();
        var monthDate = new Date(year, month + 1, 0); // Last day of month (day 0 of next month)

        var $calendar = $('<div class="calendar"></div>');

        // Add headers
        $calendar.append('<div class="row header"><div class="item">Ma</div><div class="item">Di</div><div class="item">Wo</div><div class="item">Do</div><div class="item">Vr</div><div class="item">Za</div><div class="item">Zo</div></div>');

        // Loop days
        var skipDaysStart = new Date(year, month, 1).getDay();
        skipDaysStart = skipDaysStart === 0 ? 6 : skipDaysStart - 1;
        var skipDaysEnd = (7 - (monthDate.getDate() + skipDaysStart) % 7);

        var $items = $("<div></div>");

        for (var i = 0; i < skipDaysStart; i++) {
            $items.append('<div class="item item-skip"></div>');
        }

        for (var i = 1; i <= monthDate.getDate() ; i++) {
            var isToday = year == today.getFullYear() && month == today.getMonth() && i == today.getDate();
            var isActive = activeDays[year + "" + (month + 1) + "" + i];
            $items.append('<div class="item ' + (isToday ? " item-today " : "") + (isActive ? " item-active " : "") + '">' + i + "</div>");
        }

        for (var i = 0; i < skipDaysEnd; i++) {
            $items.append('<div class="item item-skip"></div>');
        }

        // Put days in rows
        var count = ($items.children().length / 7);
        for (var j = 0; j < count; j++) {
            $('<div class="row"></div>').append($items.children().slice(0, 7)).appendTo($calendar);
        }

        return $calendar;
    };

    var setDeliveryDay = function ($picker, day) {
        $($picker.data("dayElement")).val(day);
        $picker.find("#calendar-day-picker > span").removeClass("active");
        console.log("1");
        var $activeDay = $picker.find('#calendar-day-picker > span[data-day="' + day + '"]');
        $activeDay.addClass("active");
        console.log("2");
        var dayData = $activeDay.data("days");

        var months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
        var today = new Date();
        var nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

        $picker.find("#calendar-container").empty();

        $('<div class="calendar-month">' + months[today.getMonth()] + " " + today.getFullYear() + "</div>").appendTo("#calendar-container");
        var $firstMonth = drawMonth(today.getFullYear(), today.getMonth(), dayData).appendTo("#calendar-container");

        if (dayData.length > $firstMonth.find(".item-active").length) {
            $('<div class="calendar-month">' + months[nextMonth.getMonth()] + " " + nextMonth.getFullYear() + "</div>").appendTo("#calendar-container");
            drawMonth(nextMonth.getFullYear(), nextMonth.getMonth(), dayData).appendTo("#calendar-container");
        }
        console.log("3");
    };

    var refreshAvailableDays = function ($picker, postalCode) {
        var url = $("#checkout-calendar").attr("data-url");

        $picker.find("#checkout-calendar-available, #checkout-calendar-unavailable").hide();
        $picker.find(":submit").prop("disabled", true); // Todo event

        $.ajaxAction(url + "?postalCode=" + postalCode).done(function (data) {

            if ($.isEmptyObject(data)) {
                $picker.find("#checkout-calendar-unavailable").show();
                return;
            }

            $("#calendar-day-picker > span").each(function () {
                var dayData = data[$(this).attr("data-day")];
                $(this).toggleClass("available", !!dayData).data("days", dayData);
            });

            $picker.find("#checkout-calendar-available").show();
            $picker.find(":submit").prop("disabled", false); // Todo event

            setDeliveryDay($picker, $($picker.data("dayElement")).val());
        });
    };
    
    $.fn.calendarDayPicker = function (options) {
        return this.each(function () {
            var $picker = $(this);
            
            $picker.data("dayChanged", options.dayChanged);
            $picker.data("dayElement", options.dayElement);

            $picker.find("#calendar-day-picker").on("click", "> span.available", function () {
                setDeliveryDay($picker, $(this).attr("data-day"));
            });
            
            refreshAvailableDays($picker, $(options.postalCode).val());
        });
    };
}(jQuery));